:root{
  --border_color: #F0F0F0;
}

*{
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 1rem;
}
body {
  margin: 0;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85)  ;
  height: calc(100vh - 47px) !important;
}
figure, ul, p{
  margin: 0 !important;
  padding: 0
}

#root > .with_header{
  height: calc(100vh - 46px);
}

#root > .without_header{
  height: 100vh;
}
ul li {
  list-style: none;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .container{
  box-sizing: border-box;
  margin-left: 208px;
  margin-top: 48px;
  background: #F0F2F5;
  height: 100%;
  overflow: scroll;
} */

/* .container > .table_block, .step_block{
  box-sizing: border-box;
  height: calc(100% - 102px) ;
} */
.container > .card_table{
  margin: 24px;
  background: white;
}
.container > .table_block{
  margin: 24px;
  padding: 0 24px;
  background: white;
  /* height: calc(100% - 48px) ;
  height: 100%; */
}
.step_block{
  padding: 0;
  min-height: calc(100% - 102px - 24px) ;
  height: unset;
  margin-top: 0;
  ;
}
 .step_content{
  padding: 24px;
  margin: 0 24px;
  background: white;

}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description{
  max-width: unset !important;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.expand-parent{
  background: #FAFAFA ;
}
.ant-table-tbody > .expand-parent > td{
  border-bottom: none;
  font-weight: 500;
}

.ant-collapse{
  border: none !important;
  background-color: white !important;
  /* border-bottom: 1px solid rgba(240, 240, 240, 1)  !important; */

}

.ant-collapse > .ant-collapse-item,.ant-collapse-content{
  border: none !important;

}
.collapseCar .ant-collapse > .ant-collapse-item,.ant-collapse-content{
  border-bottom: 1px solid rgba(240, 240, 240, 1)  !important;

}
.ant-collapse-content > .ant-collapse-content-box{
  background: #FAFAFA;
}

.info_block .ant-collapse > .ant-collapse-item {
  padding: 0 0 8px 0 !important;
  margin: 8px 0;
  /* margin-bottom: 16px; */
}
.info_block .ant-collapse, .case_table .ant-collapse{
  border: none !important;
}

.case_table .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header
{
  padding: 8 !important;
}
.info_block .ant-collapse > .ant-collapse-item  .ant-collapse-content{
  margin-top: 8px;
}
 .ant-collapse > .ant-collapse-item > .ant-collapse-header{
  align-items: center !important;
  padding: 0px 8px !important;
}
.ant-collapse-header-text{
  width: 100%;
}
.ant-timeline-item{
  padding-bottom:  0 !important;
  padding-top: 16px;
}


/* 使用檔案 components/points/point/CustomCalendar.js */
.list_tooltip{
  margin-bottom: 10px;
}
.list_tooltip::before{
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 50%;
  margin-right: 8px !important;
}

.car_detail_select{
  background-color: rgba(250, 250, 250, 1) !important;
}
.car_detail_select.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: transparent !important;
}
.ant-upload-list{
  margin-bottom: 8px !important;
}


.ant-table-pagination.ant-pagination{
  margin-top: 16px !important;
}
.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
  /* padding-top: 16px; */
}

/* 案件編輯- 車輛資訊 */
.remove-col-16 >.ant-col-16 {
  max-width: 100%;
}


/* //tms/customer/lists/{customerId}/add(新增計價模組） */
.pricingModule_contractFile .ant-form-item-control-input-content >span{
  display: flex !important;
}
.antd-input-upload .ant-upload-list,.ant-upload-span{
  max-width: 304px;
}
.ant-pagination-options{
  display: none !important;
}


/* font-size */
.ant-form,.ant-form-item-label > label,.ant-tag, input,.ant-statistic-title,.ant-input-group-addo, .ant-input-number-group-addon, .ant-input-group-addon{
  font-size: 16px !important;
}
.ant-tag{
  padding: 4px 8px !important;
}
.ant-form-item-with-help .ant-form-item-explain{
  min-height: unset !important;
}
.ant-btn{
  height: auto !important;
}
.ant-tabs-tab-btn >div{
  display: flex;
  align-items: center;
}


/* 歷程 */
.history_block{
  padding: 32px;
  background: rgba(250, 250, 250, 1);
  height: 100%;
  overflow-y: scroll;
}
.history_block ul >li{
  display: grid;
  grid-template-columns: calc(100% - 130px - 16px) 130px;
  grid-gap: 16px ;
}
.history_block ul >li>p:last-child{
  text-align: right;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  height: 35px !important;
}
.execute_detail_block{
  margin-bottom: 24px;
}
.execute_detail_block > button{
  border: 1px solid #D9D9D9;
  padding:24px;
  text-align: left;
  display: block;
  background: white;
  cursor: pointer;
}
.execute_detail_block > .disabled{
  background-color: rgba(245, 245, 245, 1);
  color:rgba(0, 0, 0, 0.25);
  cursor: default;
}

.execute_detail_block > .active{
  color:rgba(9, 109, 217, 1);
  border: 1px solid rgba(9, 109, 217, 1);
}

.ant-statistic-content-value,.ant-statistic-content-value-int, .ant-statistic-content-value-decimal{
  font-size: 24px;
}

/* 生理監控 */
.grid_double_block{
  padding-top: 24px;
}
.grid_double_block > li{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 16px;
    grid-gap: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);

}
.grid_double_block > li:nth-child(1){
  background: rgba(250, 250, 250, 1);
}
.grid_double_block > li:nth-child(1)>div:nth-child(1){
  border-right: 1px solid rgba(0, 0, 0, 0.03);
}


.calendar_table {
  width: 100%;
  padding-bottom: 24px;
}

.calendar_table thead tr > td{
  padding: 16px;
  background-color: #FAFAFA ;
  position: relative;
 
}
.calendar_table thead tr > td::after{
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 22px;
  width: 1px;
  background-color:rgba(0, 0, 0, 0.03) ;

}
.calendar_table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

}
.calendar_table tbody tr>td{
  padding: 16px;
}
.calendar_table tbody tr>.date_td{
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(15,1fr);
  height: 58px;
}
.calendar_table tbody tr>.date_td >div{
  position: relative;
  display: grid;
  grid-template-columns: repeat(2,1fr);

}
.calendar_table tbody tr>.date_td >div::after{
  align-items: center;
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #F5F5F5;
}
.calendar_table tbody tr>.date_td >div>.work{
  position: absolute;
  content: "";
  width: 50%;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  background-color: #D9F7BE;
}
.calendar_table tbody tr>.date_td >div>div:last-child{
  right: 0;
}
.calendar_table tbody tr>.date_td >div>div:first-child{
  left: 0;
}
.calendar_table tbody tr>.date_td >div>.rest, .calendar_table tbody tr>.date_td >div>.notValid{
  position: absolute;
  content: "";
  width: 50%;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  background-color: #FFCCC7;
}
/* .calendar_table tbody tr>.date_td >div>.notValid{
  background-color: rgba(250, 140, 22,0.2);
} */

.calendar_table tbody tr>.date_td >div>.rest_start::before{
  position: absolute;
  height: 4px;
  width: 4px;
  background: #FF4D4F;
  content: '';
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  left: 0;
}
.calendar_table tbody tr>.date_td >div>.notValid_start::before{
  position: absolute;
  height: 4px;
  width: 4px;
  background: #FF4D4F;
  content: '';
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  left: 0;
}
.calendar_table tbody tr>.date_td >div>.work_start::before{
  position: absolute;
  height: 4px;
  width: 4px;
  background: #73D13D;
  content: '';
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  left: 0;
}

.calendar_table tbody tr>.date_td > .workingPeriod::before{
  position: absolute;
  height: 8px;
  width: 8px;
  background:  #73D13D;
  content: '';
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  left: 0;
}
.calendar_table tbody tr>.date_td > .workingPeriod_0::before{
  left: 0;
}
.calendar_table tbody tr>.date_td > .workingPeriod_50::before{
  left: 40%;
}

.calendar_table thead tr > td:last-child:after{
  width: 0;
}
.calendar_table .date_td{
  display: grid;
  grid-template-columns: repeat(15,1fr);
}
.calendar_table .date_td >div{
  width: 100%;
}


.tag_user .ant-form-item-label>label{
    width: 140px;
}
.tag_user .ant-form-item-label>label::after{
  content: "";
}


.add_step_2_footer >.label_block{
  position: relative;
}
.add_step_2_footer .label_block::after{
  position: absolute;
  right: 0;
  top: 50%;
  content: "";
  width: 1px;
  height: 24px;
  background: rgba(0, 0, 0, 0.05);;
}
.add_step_2_footer .label_block:last-child::after{
  width: 0;
}
.add_step_2_footer .label_block>label{
  font-size: 14px;
  color: rgba(0,0,0,0.45);
}
.add_step_2_footer .label_block>p{
  font-size: 24px;
  margin-top: 6px;
}

.add_step_2_footer .label_block>.phone{
  color:rgba(24, 144, 255, 1);
}

.ant-alert{
  align-items: flex-start !important;
 
}
.ant-alert-icon{
  position: relative;
  top: 4px;
}

.custom_radio{
  display: inline-flex;
  border: 1px solid #D9D9D9;
}
.custom_radio >li{
  padding: 2px 16px;
  border-right:  1px solid #D9D9D9;
  cursor: pointer;
  /* border: ; */
}
.custom_radio >li:hover{
  color: #1890ff;
}
.custom_radio >li.active{
  background-color: #1890ff;
  border-right-color: #40a9ff;
  color: white;
}

.custom_radio >li:last-child{
  border: none;
}


.work_status_block{
  display: flex;
  justify-content: space-between;
}

.work_status_block> div .title{
  font-size: 24px;
  margin-top: 6px;
}

.route_table>.route_table_thead{
  background: #FAFAFA;
  display: flex;
  border-bottom: 1px solid #f0f0f0 ;
  margin-bottom: 8px;
  
}
.route_table_tr{
  display: flex;
  border-bottom: 1px solid #f0f0f0 ;
  cursor: pointer;
  margin-bottom: 8px;
}
.route_table_tr:hover{
  background-color: rgba(230, 247, 255, 1);
}
.route_table_tr.disabled{
  background: #F5F5F5;
  color: rgba(0, 0, 0, 0.45);
  cursor: default;
}
.route_table_tr .specified{
  color:rgba(24, 144, 255, 1);
}

.tab_block{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px;
  padding-bottom: 12px;
  padding-top: 8px;

}
.tab_block>li{
  padding: 6px 16px;
  box-shadow: inset -1px 0px 0px #D9D9D9, inset 0px 1px 0px #D9D9D9, inset 0px -1px 0px #D9D9D9;


}
.tab_block>li:first-child{
  border-left: 1px solid #D9D9D9;

}
.tab_block>li.active{
  background: #1890FF;
  box-shadow: inset -1px 0px 0px #1890FF, inset 0px 1px 0px #1890FF, inset 0px -1px 0px #1890FF;
  color: white;
}

.disabled_info_block{
  width: 100%;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  background: #FAFAFA;

}

.disabled_info_block >p{
  font-size: 20px;

}

.filter_tabs_block{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  grid-gap: 8px;

}

.filter_tabs_block>.tab{
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  padding: 2px 8px;
  cursor: pointer;
}

.photo_slider{
  position: relative;
  width: 100%;
  max-height: 800px;
  overflow: hidden;
}
.photo_slider>figure{
  display: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.photo_slider>figure>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.photo_slider>figure.active{
  display: block;
}
.photo_slider >.pagination{
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 8px;
  position: absolute;
  bottom: 0;
  left:0;
  padding: 16px 0;
  background-color: rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 6px 24px rgba(0, 31, 92, 0.1); */
}
.photo_slider >.pagination>li{
  width: 24px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
.photo_slider >.pagination>li.active{
  background-color: white;
}

.table_block .ant-spin-nested-loading, .table_block .ant-table, .table_block .ant-spin-container, .table_block .ant-table-container,.table_block .ant-table-content , .table_block table{
  height: 100%;
}

.input-error{
  position: relative;
  top: -20px;
}

.route_list{
  display:grid;
  grid-template-columns:repeat(2,1fr) ;
  grid-gap: 8px;
  margin: 16px 0;
}
.route_list>.route_block{
  display:flex;
  align-items: center;
  grid-gap: 8px;
  border:1px solid rgba(217, 217, 217, 1);
  cursor: pointer;
  padding: 16px 24px;
  position: relative;

}
.route_list>.route_block.disabled{
  background-color: #F3F3F3;
  cursor: default;
}
.route_list>.route_block .info{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  padding-top: 8px;
}
.route_list>.route_block .title{
  flex-grow: 1;
}
.route_list>.route_block.disabled p{
  color: rgba(0, 0, 0, 0.45);
}

.route_list>.route_block>.route_tab{
  width: 32px;
  height: 32px;
  background: rgba(24, 144, 255, 1)  ;
  border-radius: 50%;
  color: white;
  text-align: center;
  padding: 2px 0;
}

.route_list>.route_block> .route_dropdown{
  position: absolute;
  width: 100%;
  margin-top: 8px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: white;


}